import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage, Img } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ThemeContext from "../../context/ThemeContext"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faDrupal,
  faJoomla,
  faWordpress,
  faMagento,
  faReact,
  faNodeJs,
  faAws,
  faGoogle,
  faHackerNewsSquare,
} from "@fortawesome/free-brands-svg-icons"

const myUrl = "https://www.techimpace.com/1.jpg"

const WebDesigning = data => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo title="Best Website Designing Service Provider in India at affordable price" />
          <section
            id="section1"
            data-section-name="Branding Home"
            className={
              theme.dark
                ? " fullscreen text-light bg-branding p-0"
                : " fullscreen light p-0"
            }
          >
            <div className="container">
              <div className="container-fullscreen">
                <div className="text-middle">
                  <div className="row">
                    <div className="col-lg-6">
                      <StaticImage
                        src={myUrl}
                        alt="Website Designing"
                        placeholder="blurred"
                      />
                    </div>
                    <div className="col-lg-6 text-dark">
                      <h1 className="text-lg">
                        WEB
                        <br />
                        DESIGNING
                      </h1>
                      <p className="lead">
                        Your website is the face of your business on the
                        internet. It's the first thing potential customers see
                        when they find you online, and it can make a big
                        difference in whether or not they become customers.
                        That's why it's important to have a website that is
                        well-designed, informative, and easy to use.
                      </p>
                      <p className="lead m-b-80">
                        At Techimpace, we specialize in creating custom websites
                        that meet the unique needs of our clients. We work with
                        you to understand your business goals and target
                        audience, and we then design a website that is tailored
                        to help you achieve those goals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className={theme.dark ? "dark dark-3" : "bg-sky-50"}>
            <div className="container">
              {theme.dark ? (
                <div className="heading-text text-center m-b-100">
                  <h2 className="text-md">
                    <span>Our Specilization</span>
                  </h2>
                  <p>We offer a wide range of website design services</p>
                </div>
              ) : (
                <div className="heading-text heading-gradient text-center m-b-100">
                  <h2 className="text-md">
                    <span>Our Specilization</span>
                  </h2>
                  <p>We offer a wide range of website design services</p>
                </div>
              )}

              <div className="row">
                <div className="col-lg-4">
                  <div className="icon-box effect medium border center">
                    <FontAwesomeIcon
                      icon={faReact}
                      size="4x"
                      className={theme.dark ? " text-light m-b-20" : "m-b-20"}
                    />
                    <h3>Modern Website Designing</h3>
                    <p>
                      Elevate your brand with a modern website which is made
                      with modern technologies
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="icon-box effect medium border center">
                    <FontAwesomeIcon
                      icon={faNodeJs}
                      size="4x"
                      className={theme.dark ? " text-light m-b-20" : "m-b-20"}
                    />
                    <h3>Dynamic Website Designing</h3>
                    <p>
                      Unleash the Power of Dynamic Web Design. Dynamic Designs,
                      Adaptive Solutions
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="icon-box effect medium border center">
                    <FontAwesomeIcon
                      icon={faMagento}
                      size="4x"
                      className={theme.dark ? " text-light m-b-20" : "m-b-20"}
                    />
                    <h3>E-commerce Website Designing</h3>
                    <p>
                      Capture clicks, convert sales. We designs Ecommerce that
                      create sell & money.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="icon-box effect medium border center">
                    <FontAwesomeIcon
                      icon={faWordpress}
                      size="4x"
                      className={theme.dark ? " text-light m-b-20" : "m-b-20"}
                    />
                    <h3>WordPress Website Designing</h3>
                    <p>
                      We design Pixel Perfect WordPress websites that Work for
                      the Modern Age.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="icon-box effect medium border center">
                    <FontAwesomeIcon
                      icon={faDrupal}
                      size="4x"
                      className={theme.dark ? " text-light m-b-20" : "m-b-20"}
                    />
                    <h3>Drupal website design</h3>
                    <p>
                      Let Our Drupal Expertise Build Your Online Success. Your
                      Gateway to Digital Success
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="icon-box effect medium border center">
                    <FontAwesomeIcon
                      icon={faJoomla}
                      size="4x"
                      className={theme.dark ? " text-light m-b-20" : "m-b-20"}
                    />
                    <h3>Joomla website design</h3>
                    <p>
                      we design Joomla Websites that's Powerful, Affordable, and
                      Easy to Manage.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className={theme.dark ? "dark" : "bg-branding"}>
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="heading-text">
                    <h2 className="display-1">Its Always Free!</h2>
                    <p className="lead">
                      Yes!! We also offer a variety of services supporting
                      website designing absolutely free.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="icon-box effect large center process">
                    <div className="icon">
                      <FontAwesomeIcon
                        icon={faGoogle}
                        size="4x"
                        className={theme.dark ? "m-t-20 text-light" : "m-t-20"}
                      />
                    </div>
                    <h3>Domain name registration</h3>
                    <p>Get a free domain registration for your project</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="icon-box effect large center process">
                    <div className="icon">
                      <FontAwesomeIcon
                        icon={faAws}
                        size="4x"
                        className={theme.dark ? "m-t-20 text-light" : "m-t-20"}
                      />
                    </div>
                    <h3>Cloud Hosting</h3>
                    <p>
                      Who don't love the power of cloud hosting! We tooo. We
                      offer free cloud hosting
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 ">
                  <div className="icon-box effect large center process">
                    <div className="icon">
                      <FontAwesomeIcon
                        icon={faHackerNewsSquare}
                        size="4x"
                        className={theme.dark ? "m-t-20 text-light" : "m-t-20"}
                      />
                    </div>
                    <h3>SSL Certificate</h3>
                    <p>
                      You might don't know about it, but hackers DO! That's why
                      we use SSL
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="m-b-30">
                    Why Choose Techimpace for Your Website Designing?
                  </h3>
                  <ul>
                    <li>
                      <b>We are experienced and knowledgeable: </b>
                      Our team of website designers and developers has years of
                      experience creating websites of all sizes and complexity.
                      We are up-to-date on the latest trends and technologies,
                      and we can help you create a website that is both visually
                      appealing and functional.
                    </li>
                    <li>
                      <b>We are customer-focused: </b>
                      We take the time to understand your business goals and
                      target audience, and we work closely with you to create a
                      website that meets your specific needs. We are also
                      committed to providing excellent customer service, and we
                      are always available to answer your questions and address
                      your concerns.
                    </li>
                    <li>
                      <b>We offer a wide range of services: </b>
                      We offer a wide range of website design and development
                      services, so you can get everything you need from one
                      place. We can also help you with other aspects of your
                      online presence, such as web hosting, domain name
                      registration, and SEO.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="m-0 p-0">
            <div className="container-fluid">
              <div className="row">
                <div className="call-to-action p-t-100 p-b-100  mb-0 call-to-action-dark">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3>Contact Us Today for a Free Consultation</h3>
                        <p>
                          If you are interested in learning more about our
                          website design services, please contact us today for a
                          free consultation. We will discuss your needs and
                          requirements in detail, and we will develop a custom
                          proposal for you. We look forward to working with you
                          to create a website that you love!
                        </p>
                        <Link
                          to="/contact"
                          className="btn btn-warning text-dark"
                        >
                          Call us now!
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}
export default WebDesigning
export const query = graphql`
  query Service {
    file(relativePath: { eq: "gatsby-icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
